import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { SongsQuery } from '../../types/graphql-types'
import Layout from '../components/Layout'
import { Title } from '../components/Typography'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Link as MuiLink,
} from '@material-ui/core'
import { moment } from '../utils/moment'

const useStyles = makeStyles(theme => ({
  header: { fontWeight: 700 },
  year: { backgroundColor: theme.palette.grey[200] },
}))

type SourceProps =
  | {
      exist?: boolean
      source?: string
      link?: string
    }
  | undefined

const Source: React.FC<SourceProps> = props => {
  if (!props.exist) {
    return <>－</>
  } else if (props.link) {
    return (
      <MuiLink href={props.link} target="_blank" rel="noopener noreferrer">
        {props.source ?? '○'}
      </MuiLink>
    )
  } else {
    return <>{props.source ?? '○'}</>
  }
}

const Songs: React.FC<PageProps<SongsQuery>> = ({ data }) => {
  const classes = useStyles()
  return (
    <Layout path="Songs">
      <Title>Songs</Title>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.header}>楽曲</TableCell>
              <TableCell className={classes.header}>音源</TableCell>
              <TableCell className={classes.header}>動画</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.allMicrocmsSongs.edges.map(edge => {
              return (
                <>
                  {moment(edge.node.date).year() !==
                    moment(edge.previous?.date).year() && (
                    <TableRow className={classes.year}>
                      <TableCell colSpan={3}>
                        {moment(edge.node.date).year()}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>{edge.node.title}</TableCell>
                    <TableCell>
                      <Source {...edge.node.sound} />
                    </TableCell>
                    <TableCell>
                      <Source {...edge.node.video} />
                    </TableCell>
                  </TableRow>
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  )
}

export default Songs

export const query = graphql`
  query Songs {
    allMicrocmsSongs(sort: { fields: date, order: DESC }) {
      edges {
        node {
          date
          title
          sound {
            exist
            link
            source
          }
          video {
            exist
            link
            source
          }
        }
        previous {
          date
        }
      }
    }
  }
`
